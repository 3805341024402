<template>
  <div class="page404">
    <a class="back-icon" href="javascript:history.back(-1)">
      <img alt="" src="../assets/images/left-arrow.png" />
    </a>
    <div class="btn-download flex-c" @click="downloadApp">Outline 下载</div>
    <div class="key-value">
     <textarea class="key-value" id="code" cols="30" rows="1" contentEditable=true style="background:#343445;font-size:18pt;"></textarea>
    </div>
    <div class="btn-copy flex-c" @click="copyKeyValue">复制密钥</div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { isMobile } from '../utils'
// import { get, post } from '../axios'
export default {
  name: 'pageError',
  methods: {
    downloadApp() {
      window.location.href = 'https://getoutline.org/get-started/#step-3'
    },
    copyKeyValue() {
      const text = document.querySelector('.key-value').innerHTML

      var i = document.getElementById("code");
      i.select();
      document.execCommand("Copy");
      this.$message.success('复制成功', 2);
      navigator.clipboard
               .writeText(text)
               .then(() => {
                 this.$message.success('复制成功', 2)
               })
               .catch(() => {
                 this.$message.error('此浏览器不支援', 2)
               })
    }
  }
}

window.onload = function() {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        var res = JSON.parse(this.responseText);
        if(res["code"] == 'success'){
            var info = JSON.parse(res["data"]);
            document.getElementById("code").value = info["base_code"];
        }
      }
    };
    xhttp.open("GET", "/api/gameRrrorPage1.do", true);
    xhttp.send();
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.page404 {
  background-image: url('~@/assets/images/404.png');
  background-size: 100% 100%;
  max-width: 30rem;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.back-icon {
  img {
    width: 1.2rem;
  }

  position: absolute;
  left: 0.2rem;
  top: 0.5rem;
}

.btn-download {
  width: 8rem;
  padding: 0.5rem 0;
  margin: 0 auto;
  background-color: white;
  color: black;
  font-size: 1rem;
  border-radius: 1rem / 1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  cursor: pointer;
}

.key-value {
  position: absolute;
  width: 100%;
  padding: 0 10px;
  left: 0;
  right: 0;
  top: 75%;
  color: white;
  font-size: 0.6rem;
  word-break: break-all;
}

.btn-copy {
  width: 8rem;
  padding: 0.1rem 0;
  margin: 0 auto;
  color: white;
  font-size: 0.9rem;
  border-radius: 1rem / 1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 80%;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

@include md {
}
</style>
